import { Typography } from '@mui/material';
import { styled, keyframes } from '@mui/system';

const scrollAnimation = keyframes`
  from {
    transform: translateX(50%);
  }
  to {
    transform: translateX(-250%);
  }
`;

const RootDiv = styled('div')(() => ({
  backgroundColor: '#E6F3FF',
  height: 'auto',
  padding: '64px 4px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 16,
  paddingRight: 0,
  paddingBottom: 32,
}));

const InsuranceTitle = styled(Typography)(({ theme }) => ({
  fontSize: 32,
  fontWeight: 600,
  fontFamily: 'Poppins',
  color: '#73A9FF',
  textAlign: 'center',
  [theme.breakpoints.down('md')]: {
    fontSize: 24,
  },
}));

const InsuranceSubtitle = styled(Typography)(({ theme }) => ({
  fontSize: 56,
  fontWeight: 500,
  fontFamily: 'Poppins',
  color: '#1C3D72',
  textAlign: 'center',
  lineHeight: 'normal',
  [theme.breakpoints.down('md')]: {
    fontSize: 24,
  },
}));

const InsuranceImagesContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  justifyContent: 'center',
  left: 0,
  right: 0,
  flexDirection: 'row',
  gap: 8,
  overflowX: 'auto',

  padding: '4px 64px',
  scrollbarWidth: 'none',
  '-ms-overflow-style': 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  [theme.breakpoints.down('md')]: {
    padding: '0px 16px',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
}));

const ScrollingWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  animation: `${scrollAnimation} 10s linear infinite`,
  alignItems: 'left',
}));

const InsuranceImage = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  flex: '0 0 auto',
}));

export {
  RootDiv,
  InsuranceTitle,
  InsuranceSubtitle,
  InsuranceImagesContainer,
  InsuranceImage,
  ScrollingWrapper,
};
