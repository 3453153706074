import { styled } from '@mui/system';
import { Button, Typography } from '@mui/material';

const RootDiv = styled('div')(({ theme }) => ({
  background: 'white',
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  padding: '68px 0px',

  [theme.breakpoints.down('md')]: {
    padding: '32px 0px',
  },
}));

const PriceComparatorTitle = styled(Typography)(({ theme }) => ({
  fontSize: 56,
  fontWeight: 500,
  fontFamily: 'Poppins',
  color: '#1C3D72',
  [theme.breakpoints.down('md')]: {
    fontSize: 24,
  },
  textAlign: 'center',
  lineHeight: '36px',
}));

const PriceComparatorTitleContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  gap: 16,
  flexWrap: 'wrap',
  padding: '0px 16px',
}));

const PriceComparatorSubtitle = styled(Typography)(({ theme }) => ({
  fontSize: 32,
  fontWeight: 600,
  fontFamily: 'Poppins',
  color: '#73A9FF',
  [theme.breakpoints.down('md')]: {
    fontSize: 20,
    paddingTop: '0',
  },
  paddingTop: '32px',
  textAlign: 'center',
  lineHeight: '36px',
  paddingRight: 32,
  paddingLeft: 32,
}));

const UpdatePriceText = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  fontWeight: 400,
  color: '#15365F',
  textAlign: 'center',
  [theme.breakpoints.up('md')]: {
    fontSize: 14,
  },
  padding: '0px 32px',
}));

const SearchMedicineButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#0A7ABE',
  color: 'white',
  padding: '8px 24px',
  fontSize: 16,
  fontWeight: 500,
  fontFamily: 'Poppins',
  borderRadius: 100,
  position: 'relative',
  '&:hover': {
    backgroundColor: '#0A7ABE',
  },
  height: 58,
  textTransform: 'none',
  alignSelf: 'center',
  marginTop: 64,
  [theme.breakpoints.down('md')]: {
    height: 42,
    marginTop: 32,
  },
}));

export {
  RootDiv,
  PriceComparatorTitle,
  PriceComparatorTitleContainer,
  PriceComparatorSubtitle,
  UpdatePriceText,
  SearchMedicineButton,
};
