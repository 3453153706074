import { useState, useEffect } from 'react';
import {
  HeroBodyContainer,
  HeroSectionBackground,
  HeroSectionSubtitle,
  HeroSectionSubtitleContainer,
  HeroSectionTitle,
  ImageGoogle,
  PillsContainerImage,
  RankingContainer,
  RatingText,
  RootDiv,
} from './heroSection.style';
import Image from 'next/image';
import { CategoriesCard } from './categoriesCard';
import { Rating } from '@mui/material';
import { useWindowSize } from '@hooks/useWindowSize';
import { useFocusSearchBox } from '@hooks/useFocusSearchBox';
import { GOOGLE_RATING } from '@config/constants';
import { SearchBox } from '@components/searchBox';
import { useTypewriterEffect } from './useTypewriterEffect';

const phrases = ['mejores precios', 'más variedad', 'mejor servicio'];

const PHRASE_DELAY = 4000;
const CLIENTS_TEXT = '+25.000 clientes';

interface HeroSectionProps {
  searchRef: React.RefObject<HTMLDivElement>;
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
}

export const HeroSection = (props: HeroSectionProps) => {
  const { searchRef, isVisible, setIsVisible } = props;
  const [index, setIndex] = useState(0);
  const focusSearchBox = useFocusSearchBox();
  const size = useWindowSize();
  const isMobile = size.width < 1024;
  const isHeader = false;

  useEffect(() => {
    if (focusSearchBox.isFocus && isMobile) {
      setIsVisible(false);
    }
  }, [focusSearchBox.isFocus]);

  const getHighlightedPhrase = (phrase: string) => {
    const typedText = useTypewriterEffect(phrase, () =>
      setIndex((prev) => (prev + 1) % phrases.length),
    );
    const words = typedText.split(' ');
    const lastWord = words.pop();
    const isLastPhrase =
      typedText.split(' ').length === phrase.split(' ').length;

    return (
      <>
        {words.join(' ')}
        {words.length > 0 ? ' ' : ''}
        <span
          style={{
            color: isLastPhrase ? (isMobile ? 'white' : '#73A9FF') : undefined,
          }}
        >
          {lastWord}
        </span>
      </>
    );
  };

  return (
    <RootDiv>
      <HeroSectionTitle>La farmacia con </HeroSectionTitle>
      <HeroSectionSubtitleContainer>
        <HeroSectionSubtitle key={index}>
          {getHighlightedPhrase(phrases[index])}
        </HeroSectionSubtitle>
      </HeroSectionSubtitleContainer>
      <HeroSectionBackground />
      {!isMobile && (
        <PillsContainerImage>
          <Image
            src="/assets/images/newLanding/PastillasHero.svg"
            alt="Pastillas"
            height={305}
            width={440}
          />
        </PillsContainerImage>
      )}

      <HeroBodyContainer>
        <SearchBox
          isHeader={isHeader}
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          searchRef={searchRef}
        />

        <CategoriesCard />
        <RankingContainer>
          <ImageGoogle
            src="/assets/images/newLanding/googleIcon.webp"
            alt="Google Rating"
            width={32}
            height={32}
            layout="intrinsic"
          />
          <RatingText>{GOOGLE_RATING}</RatingText>
          <Rating
            defaultValue={5}
            readOnly
            size={isMobile ? 'small' : 'large'}
          />
          <RatingText>|</RatingText>
          <RatingText>{CLIENTS_TEXT}</RatingText>
        </RankingContainer>
      </HeroBodyContainer>
    </RootDiv>
  );
};
