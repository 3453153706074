import { useState } from 'react';
import { HomeNavBar } from '../components/homeNavBar';
import { DispatchBanner } from './dispatchBanner';
import { HeaderContainer } from './styles';
import { ReferralBannerDiscount } from './ReferralBannerDiscountSection/referralBannerDiscount';
import { ValidateReferenceCouponResponse } from '@queries/referenceCoupon/queries';

interface HeaderProps {
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
  referralUser?: ValidateReferenceCouponResponse['validateReferenceCoupon'];
}

export const Header = (props: HeaderProps) => {
  const { isVisible, setIsVisible, referralUser } = props;
  const [isBannerVisible, setIsBannerVisible] = useState(true);

  return (
    <HeaderContainer>
      <HomeNavBar isVisible={!isVisible} setIsVisible={setIsVisible} />

      {referralUser?.isValid && isBannerVisible ? (
        <ReferralBannerDiscount
          referenceCouponValidation={referralUser}
          setIsBannerVisible={setIsBannerVisible}
          isBannerVisible={isBannerVisible}
        />
      ) : (
        <DispatchBanner />
      )}
    </HeaderContainer>
  );
};
