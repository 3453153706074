import { useState, useEffect } from 'react';

export const useTypewriterEffect = (
  phrase: string,
  setNextPhrase: () => void,
  typingSpeed = 80,
  deleteSpeed = 40,
  delay = 2000,
  nextPhraseDelay = 1000,
) => {
  const [currentText, setCurrentText] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (!isDeleting && currentText.length < phrase.length) {
      timeout = setTimeout(() => {
        setCurrentText(phrase.slice(0, currentText.length + 1));
      }, typingSpeed);
    } else if (isDeleting && currentText.length > 0) {
      timeout = setTimeout(() => {
        setCurrentText(currentText.slice(0, -1));
      }, deleteSpeed);
    } else if (currentText.length === phrase.length) {
      timeout = setTimeout(() => {
        setIsDeleting(true);
      }, delay);
    } else if (currentText.length === 0) {
      timeout = setTimeout(() => {
        setIsDeleting(false);
        setNextPhrase();
      }, nextPhraseDelay);
    }

    return () => clearTimeout(timeout);
  }, [
    currentText,
    isDeleting,
    phrase,
    typingSpeed,
    deleteSpeed,
    delay,
    nextPhraseDelay,
  ]);

  return currentText;
};
