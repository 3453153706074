import { FREE_SHIPPING_MIN_AMOUNT } from '@config/constants';
import {
  DispatchBannerLink,
  DispatchBannerSection,
  DispatchBannerText,
} from './styles';
import { CurrencyFormatter } from '@utils/formatters/CurrencyFormatter';
import { NEXT_PUBLIC_NO_PRICE_FALLBACK } from '@config/environment';
import { useWindowSize } from '@hooks/useWindowSize';

import { useState } from 'react';
import { ModalDispatchZones } from '../components/modalDispatchZones';

export const DispatchBanner = () => {
  const currFormat = new CurrencyFormatter({
    fallback: NEXT_PUBLIC_NO_PRICE_FALLBACK,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { width } = useWindowSize();
  const isDesktop = width >= 1024;
  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);
  return (
    <DispatchBannerSection>
      <DispatchBannerText>
        Despacho gratis sobre {currFormat.format(FREE_SHIPPING_MIN_AMOUNT)}
      </DispatchBannerText>
      {isDesktop && (
        <>
          <ModalDispatchZones
            isModalOpen={isModalOpen}
            handleCloseModal={handleCloseModal}
          />
          <DispatchBannerLink onClick={handleOpenModal}>
            Ver zonas de despacho
          </DispatchBannerLink>
        </>
      )}
    </DispatchBannerSection>
  );
};
