import { Card, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/system';

const RootDiv = styled('div')(() => ({
  height: 'auto',
  padding: '64px 0px',
}));

const CommentsContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  left: 0,
  padding: '64px 0px',

  [theme.breakpoints.down('md')]: {
    padding: '8px 0px',
  },
}));

const TitleContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 42,
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: 'clamp(20px, 4vw, 32px)',
  fontWeight: 600,
  fontFamily: 'Poppins',
  color: '#73A9FF',
  textAlign: 'center',
}));

const RatingContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 24,
}));

const RatingText = styled(Typography)(({ theme }) => ({
  fontSize: 56,
  fontWeight: 600,
  fontFamily: 'Poppins',
  color: '#1C3D72',
  textAlign: 'center',
  [theme.breakpoints.down('md')]: {
    fontSize: 32,
  },
}));

const ScrollContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  width: '100%',
  justifyContent: 'center',
}));

interface ScrollButtonProps {
  direction: 'left' | 'right';
}

const ScrollButton = styled(IconButton)<ScrollButtonProps>(({ direction }) => ({
  backgroundColor: '#73A9FF',
  color: '#ffffff',
  position: 'absolute',
  zIndex: 2,
  '&:hover': {
    backgroundColor: '#8fb9fe',
  },
  right: direction === 'right' ? 80 : 'auto',
  left: direction === 'left' ? 80 : 'auto',
  marginTop: 48,
}));

const CommentsCardContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: 12,
  marginTop: 48,
  position: 'relative',
  left: 0,
  right: 0,
  overflowX: 'auto',
  scrollbarWidth: 'none',
  '-ms-overflow-style': 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  padding: '16px 44px',
}));

const CommentCard = styled(Card)(({ theme }) => ({
  backgroundColor: 'white',
  width: 400,
  height: 260,
  borderRadius: 24,
  padding: 24,
  flex: '0 0 auto',

  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  [theme.breakpoints.down('md')]: {
    width: 294,
    height: 324,
  },
  boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.1)',
}));

const CommentCardHeader = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: 24,
  alignItems: 'center',
}));

const CommentCardTitleHeader = styled(Typography)(() => ({
  fontSize: 16,
  fontWeight: 500,
  fontFamily: 'Poppins',
  color: '#1C3D72',
  textAlign: 'left',
}));

const CommentCardComment = styled(Typography)(() => ({
  fontSize: 14,
  fontWeight: 400,
  fontFamily: 'Poppins',
  color: '#414046',
  textAlign: 'left',
  lineHeight: '21px',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  WebkitLineClamp: 5,
}));

const CommentCardRating = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: 24,
  alignItems: 'flex-end',
  position: 'absolute',
  bottom: 24,
}));

export {
  RootDiv,
  CommentsContainer,
  TitleContainer,
  Title,
  RatingText,
  RatingContainer,
  CommentsCardContainer,
  CommentCard,
  CommentCardHeader,
  CommentCardTitleHeader,
  CommentCardRating,
  CommentCardComment,
  ScrollContainer,
  ScrollButton,
};
