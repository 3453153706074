import { useWindowSize } from '@hooks/useWindowSize';
import { ImageContainer, ImagesContainer, RootDiv } from './ispSection.style';
import Image from 'next/image';

const ISP_IMAGES = {
  desktop: [
    {
      image: '/assets/images/newLanding/ISP/BannerDesk2.webp',
      link: 'https://www.minsal.cl/wp-content/uploads/2016/08/30-06-2016-AFICHES_GONDOLAS-DE-FARMACIAS_final_imprimir.pdf',
    },
    {
      image: '/assets/images/newLanding/ISP/BannerDesk3.webp',
      link: 'https://www.minsal.cl/wp-content/uploads/2016/04/AFICHES-APAISADOS-1.pdf',
    },
    {
      image: '/assets/images/newLanding/ISP/BannerDesk1.webp',
      link: 'https://d1zphfo9y2yvu9.cloudfront.net/docs/Resolucion+venta+online.pdf',
    },
  ],
  mobile: [
    {
      image: '/assets/images/newLanding/ISP/BannerMob2.webp',
      link: 'https://www.minsal.cl/wp-content/uploads/2016/08/30-06-2016-AFICHES_GONDOLAS-DE-FARMACIAS_final_imprimir.pdf',
    },
    {
      image: '/assets/images/newLanding/ISP/BannerMob3.webp',
      link: 'https://www.minsal.cl/wp-content/uploads/2016/04/AFICHES-APAISADOS-1.pdf',
    },
    {
      image: '/assets/images/newLanding/ISP/BannerMob1.webp',
      link: 'https://d1zphfo9y2yvu9.cloudfront.net/docs/Resolucion+venta+online.pdf',
    },
  ],
};

export const IspSection = () => {
  const { width } = useWindowSize();
  const isDesktop = width > 1024;
  const images = isDesktop ? ISP_IMAGES.desktop : ISP_IMAGES.mobile;
  const imageWidth = isDesktop ? 550 : 274;
  const imageHeight = isDesktop ? 172 : 258;
  return (
    <RootDiv>
      <ImagesContainer>
        {images.map((image) => (
          <ImageContainer
            key={image.image}
            onClick={() => {
              window.open(image.link, '_blank', 'noopener,noreferrer');
            }}
          >
            <Image
              src={image.image}
              alt="isp1"
              width={imageWidth}
              height={imageHeight}
            />
          </ImageContainer>
        ))}
      </ImagesContainer>
    </RootDiv>
  );
};
