import {
  DispatchDescription,
  DispatchSubtitle,
  DispatchTitle,
  DispatchTitleContainer,
  RootDiv,
} from './dispatchSection.style';
import { DELIVERY_COST, FREE_SHIPPING_MIN_AMOUNT } from '@config/constants';
import { CurrencyFormatter } from '@utils/formatters/CurrencyFormatter';
import { NEXT_PUBLIC_NO_PRICE_FALLBACK } from '@config/environment';

export const DispatchSection = () => {
  const currFormat = new CurrencyFormatter({
    fallback: NEXT_PUBLIC_NO_PRICE_FALLBACK,
  });
  return (
    <RootDiv>
      <DispatchTitleContainer>
        <DispatchTitle>
          DESPACHO GRATIS SOBRE {currFormat.format(FREE_SHIPPING_MIN_AMOUNT)}
        </DispatchTitle>
        <DispatchSubtitle>Entregamos en 36 comunas de la RM</DispatchSubtitle>
        <DispatchDescription>
          Costo de despacho {currFormat.format(DELIVERY_COST)} para compras bajo{' '}
          {currFormat.format(FREE_SHIPPING_MIN_AMOUNT)}
        </DispatchDescription>
      </DispatchTitleContainer>
    </RootDiv>
  );
};
