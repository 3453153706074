import {
  RootDiv,
  TitleContainer,
  Title,
  Subtitle,
  OptionText,
  OptionContainer,
  CompareImagesContainer,
  Description,
  SearchMedicineButton,
} from './compareAndCheck.style';
import Image from 'next/image';
import { useWindowSize } from '@hooks/useWindowSize';
import { useRouter } from 'next/router';
import { getRoute } from '@utils';
import { useFocusSearchBox } from '@hooks/useFocusSearchBox';

const OPTIONS = [
  {
    title: 'Precios más bajos',
    image: '/assets/images/newLanding/SeccionComparaComprueba/PreciosBajos.svg',
  },
  {
    title: 'Mas alternativas',
    image:
      '/assets/images/newLanding/SeccionComparaComprueba/MasAlternativas.svg',
  },
];

export const CompareAndCheckSection = () => {
  const { width } = useWindowSize();
  const isDesktop = width > 1024;
  const router = useRouter();
  const focusSearchBox = useFocusSearchBox();

  return (
    <RootDiv>
      <TitleContainer>
        <Title>BUSCA TUS MEDICAMENTOS</Title>
        <Subtitle>Compara y comprueba</Subtitle>
        {!isDesktop ? (
          <Description>Precios más bajos · Más alternativas</Description>
        ) : (
          OPTIONS.map((option) => (
            <OptionContainer key={option.title}>
              <Image
                src={option.image}
                alt="Mas alternativas"
                width={40}
                height={40}
              />
              <OptionText>{option.title}</OptionText>
            </OptionContainer>
          ))
        )}
        {isDesktop && (
          <SearchMedicineButton
            onClick={() => {
              focusSearchBox.onfocus();
            }}
          >
            Buscar mis medicamentos
          </SearchMedicineButton>
        )}
      </TitleContainer>
      <CompareImagesContainer>
        <Image
          src={
            '/assets/images/newLanding/SeccionComparaComprueba/MockupTelefono.webp'
          }
          alt="Compare"
          width={521}
          height={717}
        />
      </CompareImagesContainer>
      {!isDesktop && (
        <SearchMedicineButton
          onClick={() => {
            focusSearchBox.onfocus();
          }}
        >
          Buscar mis medicamentos
        </SearchMedicineButton>
      )}
    </RootDiv>
  );
};
