import { CardActionArea, CardContent, CardMedia } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { useEffect, useRef, useState } from 'react';
import {
  CardContainer,
  CardsContainer,
  DescriptionCard,
  RootDiv,
  Title,
  TitleCard,
  ScrollContainer,
  ScrollButton,
} from './newsSection.style';
import { useWindowSize } from '@hooks/useWindowSize';

const CONTENT = [
  {
    title: 'MUCHO GUSTO ・ MEGA',
    description:
      'Medicamentos a bajo costo y con entrega a domicilio: Te contamos cómo encontrarlos',
    image: '/assets/images/newLanding/Prensa/MuchoGusto.webp',
    link: 'https://www.mega.cl/programas/mucho-gusto/mejores-momentos/154626-farmacias-baratas-medicamentos-convenientes-entrega-a-domicilio.html',
  },
  {
    title: 'EMOL',
    description:
      '"Lo pensó/lo hizo" con Juan Pablo Ossa: Meki la farmacia digital que desafía a grandes cadenas prometiendo bajos precios',
    image: '/assets/images/newLanding/Prensa/Emol.webp',
    link: 'https://www.emol.com/noticias/Economia/2024/08/29/1141082/juan-pablo-ossa-meki-farmacia.html',
  },
  {
    title: 'RADIO LA CLAVE',
    description: 'Juan Pablo Ossa nos habla de su farmacia 100% online "Meki"',
    image: '/assets/images/newLanding/Prensa/RadioLaClave.webp',
    link: 'https://www.youtube.com/watch?v=n3rlCGPCjEc',
  },
  {
    title: 'LUN',
    description:
      'El paracetamol sirve para casi todo: ¿alivia también la resaca?',
    image: '/assets/images/newLanding/Prensa/LUN.webp',
    link: 'https://www.lun.com/Pages/NewsDetail.aspx?dt=2024-07-23&SupplementId=0&BodyID=0&PaginaId=24&r=w',
  },
  {
    title: 'EL MOSTRADOR',
    description: '¿Medicamentos de marca o bioequivalentes?',
    image: '/assets/images/newLanding/Prensa/ElMostrador.webp',
    link: 'https://www.elmostrador.cl/agenda-pais/vida-en-linea/2024/09/09/medicamentos-de-marca-o-bioequivalentes/',
  },
  {
    title: 'RADIO UNIVERSO',
    description:
      'Farmacia Meki: La startup que revoluciona el acceso a medicamentos económicos en Chile',
    image: '/assets/images/newLanding/Prensa/RadioUniverso.webp',
    link: 'https://universo.cl/actualidad/farmacia-meki-la-startup-que-revoluciona-el-acceso-a-medicamentos-economicos-en-chile/',
  },
];

export default function NewsSection() {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [showLeft, setShowLeft] = useState(false);
  const [showRight, setShowRight] = useState(true);
  const { width } = useWindowSize();
  const isDesktop = width > 1024;

  const checkScrollPosition = () => {
    if (scrollRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
      setShowLeft(scrollLeft > 0);
      setShowRight(scrollLeft + clientWidth < scrollWidth);
    }
  };

  const scroll = (direction: 'left' | 'right') => {
    if (scrollRef.current) {
      const scrollAmount = 400;
      if (direction === 'left') {
        scrollRef.current.scrollBy({ left: -scrollAmount, behavior: 'smooth' });
      } else {
        scrollRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
      }
    }
  };

  useEffect(() => {
    checkScrollPosition();
    const handleScroll = () => checkScrollPosition();
    const container = scrollRef.current;

    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => container.removeEventListener('scroll', handleScroll);
    }
  }, []);

  return (
    <RootDiv>
      <Title>Meki en la prensa</Title>
      <ScrollContainer>
        {showLeft && isDesktop && (
          <ScrollButton onClick={() => scroll('left')} direction="left">
            <ArrowBack />
          </ScrollButton>
        )}
        <CardsContainer ref={scrollRef}>
          {CONTENT.map((item) => (
            <CardContainer
              key={item.title}
              onClick={() => {
                window.open(item.link, '_blank', 'noopener,noreferrer');
              }}
            >
              <CardActionArea>
                <CardMedia
                  component="img"
                  height={isDesktop ? '248' : '172'}
                  image={item.image}
                />
                <CardContent>
                  <TitleCard>{item.title}</TitleCard>
                  <DescriptionCard>{item.description}</DescriptionCard>
                </CardContent>
              </CardActionArea>
            </CardContainer>
          ))}
        </CardsContainer>
        {showRight && isDesktop && (
          <ScrollButton onClick={() => scroll('right')} direction="right">
            <ArrowForward />
          </ScrollButton>
        )}
      </ScrollContainer>
    </RootDiv>
  );
}
