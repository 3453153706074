import { Rating, Typography } from '@mui/material';
import { keyframes } from '@emotion/react';
import { height, styled, width } from '@mui/system';
import Image from 'next/image';

const RootDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  background: '#white',
  [theme.breakpoints.down('md')]: {
    background: '#004C8C',
    padding: '16px 0px',
  },
  [theme.breakpoints.up('md')]: {
    minHeight: '100vh',
  },
  position: 'relative',
}));

const HeroSectionBackground = styled('div')(({ theme }) => ({
  position: 'absolute',
  background: '#004C8C',
  width: '100%',
  top: 0,
  clipPath: 'polygon(0% 90%, 80% 0%, 100% 0%, 100% 100%, 0% 100%)',
  zIndex: 1,
  height: '100%',
  [theme.breakpoints.up('md')]: {
    minHeight: '100vh',
  },
}));

const blinkCaret = keyframes`
  from, to { border-color: transparent; }
  50% { border-color: var(--caret-color); }
`;

const HeroSectionSubtitleContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  zIndex: 2,
  marginLeft: 'clamp(10px, 5%, 120px)',
  marginRight: 'auto',
  animation: `
             ${blinkCaret} 1s steps(1) infinite;`,
  borderRight: '2px solid var(--caret-color)',
  '--caret-color': '#004C8C',
  [theme.breakpoints.down('md')]: {
    marginLeft: 'clamp(10px, 5%, 20px)',
  },
  width: 'auto',
  height: 'clamp(38px, 6vw, 72px)',
}));

const HeroSectionSubtitle = styled(Typography)(({ theme }) => ({
  fontWeight: '600',
  fontFamily: 'Poppins',
  fontSize: 'clamp(38px, 6vw, 72px)',
  lineHeight: '100%',
  color: '#004C8C',
  cursor: 'default',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  paddingBottom: 'clamp(10px, 5%, 8px)',
  [theme.breakpoints.down('md')]: {
    color: '#73A9FF',
    '--caret-color': 'white',
  },
}));

const HeroSectionTitle = styled(Typography)(({ theme }) => ({
  position: 'relative',
  zIndex: 2,
  marginLeft: 'clamp(10px, 5%, 120px)',
  paddingTop: 'clamp(10px, 5%, 60px)',
  width: 'auto',
  marginRight: 'clamp(6px, 5%, 14px)',
  marginBottom: 'clamp(10px, 5%, 20px)',
  fontFamily: 'Poppins',
  fontSize: 'clamp(38px, 6vw, 72px)',
  lineHeight: '100%',
  fontWeight: '600',
  color: '#004C8C',
  cursor: 'default',
  [theme.breakpoints.down('md')]: {
    color: 'white',
  },
}));

const RatingText = styled(Typography)(({ theme }) => ({
  fontWeight: '600',
  fontFamily: 'Poppins',
  fontSize: 'clamp(14px, 4vw, 24px)',
  lineHeight: '100%',
  color: 'white',
  cursor: 'default',
}));

const RankingContainer = styled('div')(({ theme }) => ({
  zIndex: 10,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'row',
  position: 'relative',
  gap: '2vh',
}));

const ImageGoogle = styled(Image)(({ theme }) => ({
  width: '100%',
  height: 'auto',
  maxWidth: 32,
  [theme.breakpoints.down('md')]: {
    maxWidth: 24,
  },
}));

const PillsContainerImage = styled('div')(() => ({
  position: 'absolute',
  right: 'clamp(20px, 4vw, 30px)',
  top: 'clamp(20px, 4vw, 64px)',
  zIndex: 1,
}));

const HeroBodyContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10vh',
  margin: '10vh 0px',
  [theme.breakpoints.down('md')]: {
    gap: '8vh',
  },
}));

export {
  HeroSectionBackground,
  HeroSectionTitle,
  RatingText,
  RootDiv,
  RankingContainer,
  HeroSectionSubtitle,
  ImageGoogle,
  PillsContainerImage,
  HeroSectionSubtitleContainer,
  HeroBodyContainer,
};
