import { PACsComparator } from '@interfaces/pacsComparator.interface';
import PACsCard from './pacsCard';
import { usePriceComparatorStore } from './priceComparator.store';
import {
  PriceComparatorSubtitle,
  PriceComparatorTitle,
  PriceComparatorTitleContainer,
  RootDiv,
  SearchMedicineButton,
  UpdatePriceText,
} from './priceComparator.style';
import { useFocusSearchBox } from '@hooks/useFocusSearchBox';

export interface PriceComparatorProps {
  contentPacs: PACsComparator[];
  uploadDate: string;
  uploadTime: string;
}

export default function PriceComparator() {
  const focusSearchBox = useFocusSearchBox();
  const { contentPacs, uploadDate, uploadTime } = usePriceComparatorStore();

  return (
    <RootDiv>
      <PriceComparatorTitleContainer>
        <PriceComparatorTitle>
          Precios <strong>más bajos</strong> en miles
        </PriceComparatorTitle>
      </PriceComparatorTitleContainer>
      <PriceComparatorSubtitle>DE MEDICAMENTOS 🎉 </PriceComparatorSubtitle>
      {(contentPacs?.length ?? 0) > 0 && (
        <>
          <PACsCard contentPacs={contentPacs} />
          <UpdatePriceText>
            {`Precios de portales web, actualizado el ${uploadDate} a las ${uploadTime} hrs.`}
          </UpdatePriceText>
        </>
      )}

      <SearchMedicineButton
        onClick={() => {
          focusSearchBox.onfocus();
        }}
      >
        Buscar mis medicamentos
      </SearchMedicineButton>
    </RootDiv>
  );
}
