import { Card, Typography } from '@mui/material';
import { fontSize, height, maxWidth, styled, width } from '@mui/system';

interface CategoriesCardContainerProps {
  logIn: boolean;
}

const CategoriesCardContainer = styled('div')<CategoriesCardContainerProps>(
  ({ theme, logIn }) => ({
    zIndex: 10,
    display: 'flex',
    flexDirection: 'row',
    gap: 30,
    overflowX: 'auto',
    justifyContent: logIn ? 'left' : 'center',
    padding: logIn ? 32 : 10,
    paddingLeft: logIn ? '4%' : 0,
    [theme.breakpoints.down('md')]: {
      padding: logIn ? '20px 20px' : '0px 20px',
      gap: 10,
      justifyContent: 'left',
    },
    scrollbarWidth: 'none',
    '-ms-overflow-style': 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  }),
);

const CategoryCardContainer = styled(Card)(({ theme }) => ({
  backgroundColor: '#FFFFFF',
  width: 200,
  height: 176,
  padding: 10,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 8,
  borderRadius: 8,
  flex: '0 0 auto',
  cursor: 'pointer',
  boxShadow: '0px 0px 12px rgba(0,0,0,0.3)',
  marginTop: '4px',
  [theme.breakpoints.down('md')]: {
    width: '148px',
    height: '140px',
    padding: 4,
  },
}));

const CategoryCardTitle = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  fontWeight: 600,
  color: '#414046',
  textAlign: 'center',
  fontFamily: 'Poppins',
  whiteSpace: 'pre-wrap',
  padding: '0px 10px',
  [theme.breakpoints.down('md')]: {
    fontSize: 14,
    maxWidth: 120,
  },
}));

export { CategoriesCardContainer, CategoryCardContainer, CategoryCardTitle };
