import { Card, Typography, IconButton } from '@mui/material';
import { styled } from '@mui/system';

const RootDiv = styled('div')(() => ({
  padding: '88px 0px',
  backgroundColor: '#004C8C',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 48,
  position: 'relative',
}));

const Title = styled(Typography)(() => ({
  fontWeight: 500,
  fontFamily: 'Poppins',
  color: '#FFFFFF',
  textAlign: 'center',
  fontSize: 'clamp(34px, 6vw, 56px)',
}));

const ScrollContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  width: '100%',
  justifyContent: 'center',
}));

interface ScrollButtonProps {
  direction: 'left' | 'right';
}

const ScrollButton = styled(IconButton)<ScrollButtonProps>(({ direction }) => ({
  backgroundColor: '#FFFFFF',
  color: '#004C8C',
  position: 'absolute',
  zIndex: 2,
  '&:hover': {
    backgroundColor: '#eaeaea',
  },
  right: direction === 'right' ? 100 : 'auto',
  left: direction === 'left' ? 100 : 'auto',
}));

const CardsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  justifyContent: 'left',
  left: 0,
  right: 0,
  flexDirection: 'row',
  gap: 32,
  overflowX: 'auto',
  maxWidth: '100vw',
  padding: '4px 64px',
  scrollbarWidth: 'none',
  '-ms-overflow-style': 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  [theme.breakpoints.down('md')]: {
    padding: '4px 16px',
  },
}));

const CardContainer = styled(Card)(({ theme }) => ({
  width: 400,
  height: 400,
  borderRadius: '24px',
  flex: '0 0 auto',
  [theme.breakpoints.down('md')]: {
    width: 294,
    height: 342,
  },
  cursor: 'pointer',
}));

const TitleCard = styled(Typography)(() => ({
  fontSize: 16,
  fontWeight: 600,
  fontFamily: 'Poppins',
  color: '#73A9FF',
  textAlign: 'left',
  marginBottom: 8,
}));

const DescriptionCard = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 400,
  fontFamily: 'Poppins',
  color: '#1C3D72',
  textAlign: 'left',
  [theme.breakpoints.down('md')]: {
    fontSize: 14,
  },
}));

export {
  RootDiv,
  Title,
  CardsContainer,
  CardContainer,
  TitleCard,
  DescriptionCard,
  ScrollContainer,
  ScrollButton,
};
