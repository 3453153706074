import {
  InsuranceTitle,
  InsuranceSubtitle,
  RootDiv,
  InsuranceImagesContainer,
  InsuranceImage,
  ScrollingWrapper,
} from './insuranceSection.style';
import Image from 'next/image';
import { useWindowSize } from '@hooks/useWindowSize';

const INSURANCE_TITLE = 'PAGA CON TU SEGURO';
const INSURANCE_SUBTITLE = 'Compra con reembolso automático';

const INSURANCE_IMAGES = [
  '/assets/images/newLanding/insuranceIcon/Zurich.webp',
  '/assets/images/newLanding/insuranceIcon/Sura.webp',
  '/assets/images/newLanding/insuranceIcon/Vid.Security.webp',
  '/assets/images/newLanding/insuranceIcon/BiceVida.webp',
  '/assets/images/newLanding/insuranceIcon/Bci.webp',
  '/assets/images/newLanding/insuranceIcon/VidaCamara.webp',
  '/assets/images/newLanding/insuranceIcon/Help.webp',
];

export const InsuranceSection = () => {
  const { width } = useWindowSize();
  const isDesktop = width > 1024;

  return (
    <RootDiv>
      <InsuranceTitle>{INSURANCE_TITLE}</InsuranceTitle>
      <InsuranceSubtitle>{INSURANCE_SUBTITLE}</InsuranceSubtitle>
      <InsuranceImagesContainer>
        {[...INSURANCE_IMAGES].map((image, index) => (
          <InsuranceImage key={index}>
            <Image
              src={image}
              alt="insurance"
              width={isDesktop ? 150 : 120}
              height={isDesktop ? 150 : 120}
            />
          </InsuranceImage>
        ))}
      </InsuranceImagesContainer>
    </RootDiv>
  );
};
