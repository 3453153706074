import { Rating } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import {
  CommentsContainer,
  RatingContainer,
  RatingText,
  RootDiv,
  Title,
  TitleContainer,
  CommentsCardContainer,
  CommentCard,
  CommentCardHeader,
  CommentCardTitleHeader,
  CommentCardRating,
  CommentCardComment,
  ScrollContainer,
  ScrollButton,
} from './commentsSection.style';
import Image from 'next/image';
import { useEffect, useRef, useState } from 'react';
import { useWindowSize } from '@hooks/useWindowSize';
import { COMMENTS } from './config/constants';
import { GOOGLE_RATING } from '@config/constants';

export const CommentsSection = () => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [showLeft, setShowLeft] = useState(false);
  const [showRight, setShowRight] = useState(true);
  const { width } = useWindowSize();
  const isDesktop = width > 1024;

  const checkScrollPosition = () => {
    if (scrollRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
      setShowLeft(scrollLeft > 0);
      setShowRight(scrollLeft + clientWidth < scrollWidth);
    }
  };

  const scroll = (direction: 'left' | 'right') => {
    if (scrollRef.current) {
      const scrollAmount = 400;
      if (direction === 'left') {
        scrollRef.current.scrollBy({ left: -scrollAmount, behavior: 'smooth' });
      } else {
        scrollRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
      }
    }
  };

  useEffect(() => {
    checkScrollPosition();
    const handleScroll = () => checkScrollPosition();
    const container = scrollRef.current;

    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => container.removeEventListener('scroll', handleScroll);
    }
  }, []);

  return (
    <RootDiv>
      <CommentsContainer>
        <TitleContainer>
          <Title>¿QUÉ DICEN NUESTROS CLIENTES?</Title>
          <RatingContainer>
            <Image
              src="/assets/images/newLanding/googleIcon.webp"
              alt="Google Rating"
              width={32}
              height={32}
            />
            <RatingText>{GOOGLE_RATING}</RatingText>
            <Rating defaultValue={5} readOnly size="large" />
          </RatingContainer>
        </TitleContainer>
        <ScrollContainer>
          {showLeft && isDesktop && (
            <ScrollButton onClick={() => scroll('left')} direction="left">
              <ArrowBack />
            </ScrollButton>
          )}
          <CommentsCardContainer ref={scrollRef}>
            {COMMENTS.map((comment) => (
              <CommentCard key={comment.name}>
                <CommentCardHeader>
                  <Image
                    src={comment.photo}
                    alt={comment.name}
                    width={48}
                    height={48}
                  />
                  <CommentCardTitleHeader>
                    {comment.name}
                  </CommentCardTitleHeader>
                </CommentCardHeader>
                <CommentCardComment>{`"${comment.comment}"`}</CommentCardComment>
                <CommentCardRating>
                  <Rating defaultValue={5} readOnly size="large" />
                </CommentCardRating>
              </CommentCard>
            ))}
          </CommentsCardContainer>
          {showRight && isDesktop && (
            <ScrollButton onClick={() => scroll('right')} direction="right">
              <ArrowForward />
            </ScrollButton>
          )}
        </ScrollContainer>
      </CommentsContainer>
    </RootDiv>
  );
};
