import { useEffect, useRef, useState } from 'react';
import { Footer } from '../footer';
import { FaqSection } from './FaqSection/FaqSection';
import { CenabastSection } from './cenabastSection/cenabastSection';
import { CommentsSection } from './commentsSection/commentsSection';
import { CompareAndCheckSection } from './compareAndCheckSection/compareAndCheckSection';
import { DispatchSection } from './dispatchSection/dispatchSection';
import { Header } from './header';
import { HeroSection } from './HeroSection/heroSection';
import { InsuranceSection } from './insuranceSection/insuranceSection';
import NewsSection from './newsSection/newsSection';
import { StoreLocatorSection } from './storeLocatorSection/storeLocatorSection';
import { BodyContainer } from './styles';
import { IspSection } from './ispSection/ispSection';
import { useSegment } from '@hooks/useSegment';
import { useMekiState } from '@hooks/useMekiState';
import { useRouter } from 'next/router';
import { useFacebokPixelEvent } from '@hooks/useFacebookPixel';
import { useQuery } from '@apollo/client';
import {
  ValidateReferenceCouponParams,
  ValidateReferenceCouponQuerie,
  ValidateReferenceCouponResponse,
} from '@queries/referenceCoupon/queries';
import { removeUndefineds } from '@utils';
import { useAuth } from '@hooks/useAuth';
import PriceComparator from './priceComparator/priceComparator';

export default function Landing() {
  const searchRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(true);
  const { track } = useSegment();
  const router = useRouter();
  const { codigo: code } = router.query;
  const { setReferralCode } = useMekiState();
  const { trackFBEvent } = useFacebokPixelEvent();
  const codeString = code ? decodeURI(code as string) : '';
  const { appUser: user } = useAuth();

  const { data: { validateReferenceCoupon: referralUser } = {} } = useQuery<
    ValidateReferenceCouponResponse,
    ValidateReferenceCouponParams
  >(ValidateReferenceCouponQuerie(), {
    variables: { params: { code: codeString.replace(/\s.*/g, '') } },
    fetchPolicy: 'cache-first',
    skip: !codeString.replace(/\s.*/g, ''),
  });

  useEffect(
    function sanitizeReferalCode() {
      if (
        referralUser?.type == 'referral' &&
        router.query?.codigo?.length > 6
      ) {
        const routerCode = (router.query?.codigo as string).replace(
          /\s.*/g,
          '',
        );
        void router.replace(
          {
            pathname: router.pathname,
            query: removeUndefineds({
              ...router.query,
              codigo: routerCode,
            }),
          },
          {
            pathname: router.asPath.replace(/\?.*/, ''),
            query: removeUndefineds({
              ...router.query,
              codigo: routerCode,
            }),
          },
        );
      }
    },
    [referralUser?.type, router],
  );
  useEffect(
    function trackLandingView() {
      void track('landing view', {
        logged: user ? 'true' : 'false',
        referral_link: code,
        userId: user ? user.id : 'undefined',
      });
      trackFBEvent('track', 'ViewContent', {});
    },
    [track, user, code, trackFBEvent],
  );
  useEffect(() => {
    if (code && referralUser?.isValid) {
      setReferralCode(referralUser.code);
    }
  }, [code, setReferralCode, referralUser]);

  useEffect(() => {
    const checkVisibility = () => {
      if (!searchRef.current) return;

      const rect = searchRef.current.getBoundingClientRect();
      const elementOnTop = document.elementFromPoint(rect.left + 10, rect.top);

      setIsVisible(elementOnTop === searchRef.current);
    };

    window.addEventListener('scroll', checkVisibility);
    return () => window.removeEventListener('scroll', checkVisibility);
  }, []);

  return (
    <>
      <Header
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        referralUser={referralUser}
      />
      <BodyContainer>
        <HeroSection
          searchRef={searchRef}
          isVisible={isVisible}
          setIsVisible={setIsVisible}
        />
        <PriceComparator />
        <InsuranceSection />
        <DispatchSection />
        <CommentsSection />
        <CompareAndCheckSection />
        <CenabastSection />
        <NewsSection />
        <StoreLocatorSection />
        <IspSection />
        <FaqSection />
        <Footer />
      </BodyContainer>
    </>
  );
}
