/*
 * File: referralBannerDiscount.styles.tsx
 * Project: meki
 * File Created: Wednesday, 3rd May 2023 8:43:19 pm
 * Author: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Last Modified: Wednesday, 3rd May 2023 9:33:10 pm
 * Modified By: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Copyright 2019 - 2023 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */
import { IconButton, Typography } from '@mui/material';
import { styled } from '@mui/system';

const RootDiv = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  background: '#B9D4FF',
  position: 'sticky',
  bottom: 0,
  left: 0,
  width: '100%',
  minHeight: '58px',
  maxHeight: '66px',
  zIndex: 500,
});

const GiftAmountTypography = styled(Typography)(({ theme }) => ({
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '150%',
  [theme.breakpoints.up(700)]: {
    fontSize: '18px',
  },
  letterSpacing: '-0.011em',
  color: '#15365F',
}));

const ValidityTypography = styled(Typography)(({ theme }) => ({
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '150%',
  [theme.breakpoints.up(700)]: {
    fontSize: '12px',
  },
  letterSpacing: '-0.011em',
  color: '#15365F',
}));

const CloseIconButton = styled(IconButton)(() => ({
  position: 'absolute',
  top: '5px',
  right: '5px',
}));

export { RootDiv, GiftAmountTypography, ValidityTypography, CloseIconButton };
