import { Typography } from '@mui/material';
import { styled } from '@mui/system';

const RootDiv = styled('div')(({ theme }) => ({
  padding: '88px 64px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 48,
  [theme.breakpoints.down('md')]: {
    padding: '56px 32px',
    gap: 16,
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: 'clamp(24px, 4vw, 40px)',
  fontWeight: 600,
  fontFamily: 'Poppins',
  color: '#73A9FF',
  textAlign: 'center',
  lineHeight: '150%',
}));

const SubtitleContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 48,
  padding: '0px 10%',
  [theme.breakpoints.down('md')]: {
    padding: '0px 10px',
    flexDirection: 'column-reverse',
    gap: 16,
  },
}));

const Subtitle = styled(Typography)(({ theme }) => ({
  fontSize: 32,
  fontWeight: 500,
  fontFamily: 'Poppins',
  color: '#1C3D72',
  lineHeight: '60px',
  [theme.breakpoints.down('md')]: {
    fontSize: 20,
    lineHeight: '36px',
    textAlign: 'center',
  },
}));

export { RootDiv, Title, SubtitleContainer, Subtitle };
