import { Button, Typography } from '@mui/material';
import { styled } from '@mui/system';

const RootDiv = styled('div')(({ theme }) => ({
  background: 'linear-gradient(to right, #FFFFFF 5%, #A8CFFF 100%)',
  padding: '88px 64px',
  justifyContent: 'space-between',
  display: 'flex',
  flexDirection: 'row',
  [theme.breakpoints.down('md')]: {
    padding: '32px 16px',
    flexDirection: 'column',
  },
  [theme.breakpoints.up('xl')]: {
    padding: '88px 200px',
  },
}));

const TitleContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  justifyContent: 'center',
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: 'clamp(20px, 4vw, 32px)',
  fontWeight: 600,
  fontFamily: 'Poppins',
  color: '#73A9FF',
  textAlign: 'left',
  marginBottom: 80,
  [theme.breakpoints.down('md')]: {
    textAlign: 'center',
    marginBottom: 10,
  },
}));

const Subtitle = styled(Typography)(({ theme }) => ({
  fontSize: 'clamp(24px, 4vw, 56px)',
  fontWeight: 500,
  fontFamily: 'Poppins',
  color: '#1C3D72',
  textAlign: 'left',
  marginBottom: '5vh',
  lineHeight: '150%',
  [theme.breakpoints.down('md')]: {
    textAlign: 'center',
    marginBottom: 10,
  },
}));

const Description = styled(Typography)(() => ({
  fontSize: 16,
  fontWeight: 400,
  fontFamily: 'Poppins',
  color: '#1C3D72',
  textAlign: 'center',
  marginBottom: 40,
}));

const OptionContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 16,
  marginBottom: 24,
}));

const OptionText = styled(Typography)(() => ({
  fontSize: 32,
  fontWeight: 500,
  fontFamily: 'Poppins',
  color: '#1C3D72',
  textAlign: 'left',
}));

const SearchMedicineButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#0A7ABE',
  color: 'white',
  padding: '8px 24px',
  fontSize: 16,
  fontWeight: 500,
  fontFamily: 'Poppins',
  borderRadius: 100,
  position: 'relative',
  '&:hover': {
    backgroundColor: '#0A7ABE',
  },
  height: 58,
  textTransform: 'none',
  alignSelf: 'flex-start',
  marginTop: 64,
  [theme.breakpoints.down('md')]: {
    height: 42,
    marginTop: 32,
    alignSelf: 'center',
  },
}));

const CompareImagesContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
}));

export {
  RootDiv,
  TitleContainer,
  Title,
  Subtitle,
  OptionText,
  OptionContainer,
  CompareImagesContainer,
  Description,
  SearchMedicineButton,
};
