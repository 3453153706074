import { useRouter } from 'next/router';
import {
  CategoriesCardContainer,
  CategoryCardContainer,
  CategoryCardTitle,
} from './style/categoryCard.style';
import Image from 'next/image';
import { getRoute } from '@utils';
import { useMemo } from 'react';

const categories = [
  {
    name: 'Medicamentos',
    image: '/assets/images/newLanding/CategoriesMenu/Medicamentos.svg',
    slug: 'medicamentos',
  },
  {
    name: 'Cuidado personal',
    image: '/assets/images/newLanding/CategoriesMenu/CuidadoPersonal.svg',
    slug: 'cuidado-personal',
  },
  {
    name: 'Belleza',
    image: '/assets/images/newLanding/CategoriesMenu/Belleza.svg',
    slug: 'belleza',
  },
  {
    name: 'Vitaminas y suplementos',
    image: '/assets/images/newLanding/CategoriesMenu/VitySupl.svg',
    slug: 'vitaminas-y-suplementos',
  },
  {
    name: 'Mascotas',
    image: '/assets/images/newLanding/CategoriesMenu/Mascotas.svg',
    slug: 'medicamentos',
    subCategorySlug: 'veterinaria',
  },
];

export const CategoriesCard = () => {
  const router = useRouter();
  const currentLocation = useMemo(
    () => router.asPath.includes('inicio'),
    [router.asPath],
  );

  return (
    <CategoriesCardContainer logIn={currentLocation}>
      {categories.map((category) => (
        <CategoryCardContainer
          key={category.name}
          onClick={() => {
            if (category.subCategorySlug) {
              void router.push(
                getRoute('subCategory', {
                  categorySlug: category.slug,
                  subCategorySlug: category.subCategorySlug,
                }),
              );
            } else {
              void router.push(
                getRoute('subCategories', { categorySlug: category.slug }),
              );
            }
          }}
        >
          <Image
            src={category.image}
            alt={category.name}
            width={78}
            height={78}
          />
          <CategoryCardTitle>{category.name}</CategoryCardTitle>
        </CategoryCardContainer>
      ))}
    </CategoriesCardContainer>
  );
};
