import { Typography } from '@mui/material';
import { styled } from '@mui/system';

const RootDiv = styled('div')(({ theme }) => ({
  backgroundImage: 'url(/assets/images/newLanding/MapaDespacho.webp)',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  height: '60vh',
  [theme.breakpoints.down('md')]: {
    backgroundPosition: 'center',
    backgroundImage: 'url(/assets/images/newLanding/MobMapaDespacho.webp)',
  },
}));

const DispatchTitleContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  top: 88,
  left: 0,
  padding: '64px 96px',
  zIndex: 100,
  gap: 52,
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('md')]: {
    padding: '32px 48px',
    gap: 16,
  },
}));

const DispatchTitle = styled(Typography)(({ theme }) => ({
  fontSize: 'clamp(20px, 4vw, 32px)',
  fontWeight: 600,
  fontFamily: 'Poppins',
  color: '#73A9FF',
  textAlign: 'left',
  lineHeight: '150%',
  [theme.breakpoints.down('md')]: {
    textAlign: 'center',
  },
}));

const DispatchSubtitle = styled(Typography)(({ theme }) => ({
  fontSize: 'clamp(24px, 4vw, 56px)',
  fontWeight: 500,
  fontFamily: 'Poppins',
  color: '#1C3D72',
  textAlign: 'left',
  lineHeight: '150%',
  [theme.breakpoints.down('md')]: {
    textAlign: 'center',
  },
}));

const DispatchDescription = styled(Typography)(({ theme }) => ({
  fontSize: 24,
  fontWeight: 400,
  fontFamily: 'Poppins',
  color: '#1C3D72',
  textAlign: 'left',
  [theme.breakpoints.down('md')]: {
    fontSize: 14,
    textAlign: 'center',
  },
}));

export {
  RootDiv,
  DispatchTitleContainer,
  DispatchTitle,
  DispatchSubtitle,
  DispatchDescription,
};
