import { Link } from '@mui/material';
import {
  RootDiv,
  Subtitle,
  SubtitleContainer,
  Title,
} from './cenabastSection.style';
import Image from 'next/image';
import { useWindowSize } from '@hooks/useWindowSize';
import { getRoute } from '@utils';

const CENABAST_IMAGE = '/assets/images/newLanding/Cenabast.webp';

export const CenabastSection = () => {
  const { width } = useWindowSize();
  const isDesktop = width > 1024;
  const imageSize = isDesktop ? 300 : 150;

  return (
    <RootDiv>
      <Title>REMEDIOS MÁS BARATOS Y DE CALIDAD</Title>
      <SubtitleContainer>
        <Image
          src={CENABAST_IMAGE}
          alt="Cenabast"
          width={imageSize}
          height={imageSize}
        />
        <Subtitle>
          Tenemos cientos de productos Cenabast, revísalos{' '}
          <Link
            href={getRoute('subCategory', {
              categorySlug: 'medicamentos',
              subCategorySlug: 'cenabast',
            }).toString()}
          >
            aquí
          </Link>
        </Subtitle>
      </SubtitleContainer>
    </RootDiv>
  );
};
