import { styled } from '@mui/system';

const RootDiv = styled('div')(({ theme }) => ({
  padding: '64px 0px',

  [theme.breakpoints.down('md')]: {
    padding: '56px 0px',
  },
}));

const ImagesContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  justifyContent: 'left',
  left: 0,
  right: 0,
  flexDirection: 'row',
  gap: 24,
  overflowX: 'auto',
  scrollbarWidth: 'none',
  '-ms-overflow-style': 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  padding: '0px 84px',
  [theme.breakpoints.down('md')]: {
    gap: 16,
    padding: '0px 16px',
  },
}));

const ImageContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  flex: '0 0 auto',
  cursor: 'pointer',
  paddingRight: 16,
}));

export { RootDiv, ImagesContainer, ImageContainer };
