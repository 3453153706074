import { Typography } from '@mui/material';
import { styled } from '@mui/system';

const RootDiv = styled('div')(({ theme }) => ({
  padding: '48px 88px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 12,
  [theme.breakpoints.down('sm')]: {
    padding: '24px 16px',
    paddingRight: 0,
    gap: 24,
  },
}));

const Title = styled(Typography)(() => ({
  fontSize: 'clamp(24px, 4vw, 32px)',
  fontWeight: 600,
  fontFamily: 'Poppins',
  color: '#73A9FF',
  textAlign: 'center',
}));

const DirectionContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#1C3D72',
  cursor: 'pointer',
  marginRight: '5vw',
  marginLeft: '5vw',
}));

const DirectionText = styled(Typography)(() => ({
  fontSize: 'clamp(24px, 4vw, 46px)',
  fontWeight: 500,
  fontFamily: 'Poppins',
  color: '#1C3D72',
  textAlign: 'center',
  lineHeight: '150%',
}));

const ImagesContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  justifyContent: 'left',
  left: 0,
  right: 0,
  flexDirection: 'row',
  gap: 50,
  overflowX: 'auto',
  scrollbarWidth: 'none',
  '-ms-overflow-style': 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  marginTop: 16,
  [theme.breakpoints.down('md')]: {
    gap: 16,
  },
}));

const ImageContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  flex: '0 0 auto',
}));

export {
  RootDiv,
  Title,
  DirectionContainer,
  DirectionText,
  ImagesContainer,
  ImageContainer,
};
