import { IconButton, Link, Typography } from '@mui/material';
import { styled } from '@mui/system';

const HeaderContainer = styled('div')(() => ({
  position: 'sticky',
  top: 0,
  zIndex: 1000,
}));

const DispatchBannerSection = styled('div')(() => ({
  position: 'relative',
  background: '#B9D4FF',
  height: '40px',
  width: '100%',
  marginTop: '-1px',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  gap: '8px',
}));

const DispatchBannerText = styled(Typography)(() => ({
  color: '#15365F',
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '24px',
  fontFamily: 'Poppins',
}));

const DispatchBannerLink = styled(Link)(() => ({
  color: '#15365F',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  fontFamily: 'Poppins',
  cursor: 'pointer',
}));

const BodyContainer = styled('div')(() => ({
  backgroundColor: 'transparent',
  height: '100%',
  position: 'relative',
}));

export {
  HeaderContainer,
  DispatchBannerSection,
  DispatchBannerText,
  DispatchBannerLink,
  BodyContainer,
};
