import { useEffect } from 'react';
import { useAuth } from '@hooks/useAuth';
import { getRoute } from '@utils';
import { useRouter } from 'next/router';
import { useOpenModal } from '@hooks/useModalOpenState';
import Landing from './landing';
import { AssistantButton } from './components/AssistantButton';

export function LandingMain() {
  const { appUser: user } = useAuth();
  const { isModalOpen } = useOpenModal();
  const router = useRouter();
  useEffect(() => {
    if (user && !isModalOpen) {
      void router.replace(getRoute('homeLoggedIn'));
    }
  }, [user, isModalOpen, router]);
  return (
    <>
      <Landing />
      <AssistantButton />
    </>
  );
}
