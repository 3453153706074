import { styled } from '@mui/system';
import { Typography, Accordion } from '@mui/material';

interface QuestionBoxProps {
  boxOpen: boolean;
}

const RootDiv = styled('div')({
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '32px 0px',
});

const TitleTypography = styled(Typography)(() => ({
  fontFamily: 'Poppins',
  fontSize: 'clamp(24px, 4vw, 56px)',
  lineHeight: 'clamp(24px, 4vw, 56px)',
  letterSpacing: '0.15px',
  color: '#1C3D72',
  fontWeight: 500,
  marginBottom: '50px',
}));

const QuestionTypography = styled(Typography)(({ theme }) => ({
  fontFamily: 'Poppins',
  fontSize: '16px',
  lineHeight: '24px',
  letterSpacing: '0.15px',
  fontWeight: 400,
  color: '#414046',
  [theme.breakpoints.up(992)]: {
    fontSize: '18px',
    lineHeight: '27px',
  },
}));

const AnswerTypography = styled(Typography)({
  fontFamily: 'Poppins',
  fontSize: '16px',
  lineHeight: '24px',
  letterSpacing: '0.15px',
  fontWeight: 300,
  marginBottom: '5px',
});

const AnswerTypographyLink = styled(Typography)({
  fontFamily: 'Poppins',
  fontSize: '16px',
  lineHeight: '24px',
  letterSpacing: '0.15px',
  fontWeight: 300,
  color: '#0A7ABE',
  display: 'inline',
  cursor: 'pointer',
});

const IconContainer = styled('div')({
  position: 'relative',
  height: '30px',
  width: '30px',
});

const CustomAccordion = styled(Accordion, {
  shouldForwardProp: (prop) => prop !== 'boxOpen',
})<QuestionBoxProps>(({ boxOpen }) => {
  return {
    boxShadow: 'none',
    width: '80%',
    maxWidth: '800px',
    marginBottom: '17px',
    transitionDelay: '0.1s',
    textAlign: 'left',
    '.MuiAccordionDetails-root': {
      textAlign: 'left',
      paddingTop: '25px',
      marginBottom: '17px',
      border: 'solid 1px #EAEAEA',
      borderTopWidth: '0px',
      borderEndStartRadius: '10px',
      borderEndEndRadius: '10px',
      transition: 'border-radius 0.2s',
      transitionDelay: '0.05s',
    },
    '.MuiAccordionSummary-root': {
      border: 'solid 1px #EAEAEA',
      borderBottomWidth: boxOpen ? '0px' : '1px',
      borderStartStartRadius: '10px',
      borderStartEndRadius: '10px',
      borderEndStartRadius: boxOpen ? '0px' : '10px',
      borderEndEndRadius: boxOpen ? '0px' : '10px',
      transition: 'border-radius 0.2s',
      transitionDelay: '0.05s',
    },
    '&::before': {
      height: '0px',
    },
  };
});

export {
  RootDiv,
  TitleTypography,
  QuestionTypography,
  AnswerTypography,
  IconContainer,
  CustomAccordion,
  AnswerTypographyLink,
};
