import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import { CurrencyFormatter } from '@utils/formatters/CurrencyFormatter';
import {
  BottomContainer,
  BottomTextNoInfo,
  CategoriesCardContainer,
  PriceColumn,
  ContentCard,
  PACCardContainer,
  PriceText,
  ProviderNameDiv,
  RankingComparatorColumn,
  RowComparator,
  SalePriceText,
  TitleCard,
  TitleComparatorRow,
  PriceTextContainer,
  ScrollButton,
  ScrollContainer,
} from './pacsCard.style';
import { useEffect, useRef, useState } from 'react';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { useWindowSize } from '@hooks/useWindowSize';

const currencyFormatter = new CurrencyFormatter();

export default function PACsCard({ contentPacs }) {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [showLeft, setShowLeft] = useState(false);
  const [showRight, setShowRight] = useState(true);
  const { width } = useWindowSize();
  const isDesktop = width > 1024;

  const checkScrollPosition = () => {
    if (scrollRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
      setShowLeft(scrollLeft > 0);
      setShowRight(scrollLeft + clientWidth < scrollWidth);
    }
  };

  const scroll = (direction: 'left' | 'right') => {
    if (scrollRef.current) {
      const scrollAmount = 400;
      if (direction === 'left') {
        scrollRef.current.scrollBy({ left: -scrollAmount, behavior: 'smooth' });
      } else {
        scrollRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
      }
    }
  };

  useEffect(() => {
    checkScrollPosition();
    const handleScroll = () => checkScrollPosition();
    const container = scrollRef.current;

    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => container.removeEventListener('scroll', handleScroll);
    }
  }, []);
  return (
    <ScrollContainer>
      {showLeft && isDesktop && (
        <ScrollButton onClick={() => scroll('left')} direction="left">
          <ArrowBack />
        </ScrollButton>
      )}
      <CategoriesCardContainer ref={scrollRef}>
        {contentPacs.map((pacs) => (
          <PACCardContainer key={pacs.title}>
            <TitleCard title={pacs.title} subtitle={pacs.subtitle}></TitleCard>
            <ContentCard>
              {pacs.pharmacies.map((pharmacy, index) => {
                const firstRanked =
                  pharmacy.ranking == 1 && Boolean(pharmacy.finalPrice);
                const lastRow =
                  index === pacs.pharmacies.length - 1 &&
                  !Boolean(pacs.notFoundPrices.length);
                return (
                  <RowComparator
                    key={pharmacy.name}
                    bold={true}
                    firstRanked={firstRanked}
                    lastRow={lastRow}
                  >
                    <TitleComparatorRow firstRanked={firstRanked}>
                      <RankingComparatorColumn>
                        {firstRanked ? (
                          <WorkspacePremiumIcon
                            fontSize="small"
                            style={{ position: 'relative', right: 6 }}
                          />
                        ) : (
                          `${pharmacy.ranking}.`
                        )}
                      </RankingComparatorColumn>
                      <ProviderNameDiv>{pharmacy.name}</ProviderNameDiv>
                    </TitleComparatorRow>

                    <PriceText
                      isText={true}
                      discountColor={
                        pharmacy.name === 'Meki' && pharmacy.salePrice > 0
                      }
                      firstRanked={firstRanked}
                    >
                      desde:
                    </PriceText>

                    <PriceColumn>
                      {pharmacy.salePrice &&
                        pharmacy.salePrice < pharmacy.price && (
                          <SalePriceText>
                            {currencyFormatter.format(pharmacy.price)}
                          </SalePriceText>
                        )}
                      <PriceTextContainer>
                        <PriceText
                          bold={pharmacy.name === 'Meki'}
                          discountColor={
                            pharmacy.name === 'Meki' && pharmacy.salePrice > 0
                          }
                          firstRanked={firstRanked}
                        >
                          {pharmacy.price || pharmacy.salePrice
                            ? currencyFormatter.format(
                                pharmacy.salePrice &&
                                  pharmacy.salePrice < pharmacy.price
                                  ? pharmacy.salePrice
                                  : pharmacy.price,
                              )
                            : 'No lo encontramos'}
                        </PriceText>
                      </PriceTextContainer>
                    </PriceColumn>
                  </RowComparator>
                );
              })}
              {Boolean(pacs.notFoundPrices.length) && (
                <BottomContainer>
                  <BottomTextNoInfo>
                    No se encontraron precios en:{' '}
                    {pacs.notFoundPrices.join(', ')}
                  </BottomTextNoInfo>
                </BottomContainer>
              )}
            </ContentCard>
          </PACCardContainer>
        ))}
      </CategoriesCardContainer>
      {showRight && isDesktop && (
        <ScrollButton onClick={() => scroll('right')} direction="right">
          <ArrowForward />
        </ScrollButton>
      )}
    </ScrollContainer>
  );
}
