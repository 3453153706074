import { useWindowSize } from '@hooks/useWindowSize';
import {
  DirectionContainer,
  DirectionText,
  ImageContainer,
  ImagesContainer,
  RootDiv,
  Title,
} from './storeLocator.style';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Image from 'next/image';

const STORE_IMAGES = [
  '/assets/images/newLanding/FotosFarmacia/FotoFarmacia1.webp',
  '/assets/images/newLanding/FotosFarmacia/FotoFarmacia2.webp',
  '/assets/images/newLanding/FotosFarmacia/FotoFarmacia3.webp',
];

const MEKI_MAP_URL = 'https://maps.app.goo.gl/vE8YWQJTVisMkNVy5';

export const StoreLocatorSection = () => {
  const { width } = useWindowSize();
  const isDesktop = width > 1024;
  const imageSize = isDesktop ? 380 : 280;
  return (
    <RootDiv>
      <Title>¿DÓNDE ESTAMOS?</Title>
      <DirectionContainer
        onClick={() => {
          window.open(MEKI_MAP_URL, '_blank', 'noopener,noreferrer');
        }}
      >
        {isDesktop && <LocationOnIcon sx={{ fontSize: 46 }} />}
        <DirectionText>
          Av. El Salto 4001・Local 8, Huechuraba, RM
        </DirectionText>
      </DirectionContainer>
      <ImagesContainer>
        {STORE_IMAGES.map((image) => (
          <ImageContainer key={image}>
            <Image
              src={image}
              alt="store"
              width={imageSize}
              height={imageSize}
            />
          </ImageContainer>
        ))}
      </ImagesContainer>
    </RootDiv>
  );
};
