import { Card, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/system';

const PACCardContainer = styled('div')(({ theme }) => ({
  backgroundColor: '#FFFFFF',
  padding: 8,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 8,
  borderRadius: 8,
  flex: '0 0 auto',
  cursor: 'default',
  border: '1px solid #E5E5F5',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

interface TitleCardProps {
  title: string;
  subtitle: string;
}

const TitleCard = styled(Typography)<TitleCardProps>(
  ({ theme, title, subtitle }) => ({
    position: 'relative',
    '&::before': {
      content: `"${title}"`,
      display: 'block',
    },
    '&::after': {
      content: `"${subtitle}"`,
      display: 'block',
    },
    background: '#E5E5F566',
    [theme.breakpoints.up('md')]: {
      height: 240,
      width: 156,
    },
    [theme.breakpoints.down('md')]: {
      height: 90,
      width: 254,
    },
    borderRadius: 10,
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    fontSize: 16,
    fontWeight: 600,
    fontFamily: 'Poppins',
    flexDirection: 'column',
  }),
);

const ContentCard = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    height: 240,
    width: 362,
  },
  [theme.breakpoints.down('md')]: {
    height: 204,
    width: 254,
  },
  borderRadius: 10,
  textAlign: 'center',
  display: 'flex',
  fontSize: 20,
  fontWeight: 600,
  fontFamily: 'Poppins',
  flexDirection: 'column',
  border: '1px solid #E5E5F5',
  position: 'relative',
}));

const CategoriesCardContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  gap: 30,
  overflowX: 'auto',
  padding: '64px',
  paddingBottom: 10,
  scrollbarWidth: 'none',
  '-ms-overflow-style': 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  [theme.breakpoints.down('md')]: {
    gap: 16,
    padding: '4px 16px',
  },
}));

interface RowComporatorProps {
  firstRanked?: boolean;
  bold?: boolean;
  lastRow?: boolean;
}

const RowComparator = styled('div')<RowComporatorProps>(
  ({ theme, firstRanked, bold, lastRow }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    padding: '0 16px',
    minHeight: '46px',
    borderTop: '1px solid #eaeaea',
    backgroundColor: firstRanked ? '#E5E5F5' : 'white',
    color: firstRanked ? '#5d5fef' : 'black',
    fontWeight: bold ? '600' : '',
    width: '100%',
    borderRadius: firstRanked
      ? '10px 10px 0px 0px'
      : lastRow
      ? '0px 0px 10px 10px'
      : '0px',
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      minHeight: '40px',
    },
  }),
);

const TitleComparatorRow = styled('div')<{ firstRanked?: boolean }>(
  ({ theme, firstRanked }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: !firstRanked ? 6 : 0,
    [theme.breakpoints.down('md')]: {
      gap: !firstRanked ? 8 : 0,
    },
  }),
);

const RankingComparatorColumn = styled('div')(({ theme }) => ({
  flexGrow: 1,
  textAlign: 'right',
  fontSize: 14,
  [theme.breakpoints.down('md')]: {
    fontSize: 12,
  },
}));

const ProviderNameDiv = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  font-size: 14px;
`;
const PriceColumn = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
}));

const PriceTextContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

const SalePriceText = styled(Typography)(() => ({
  fontSize: 12,
  fontWeight: 400,
  textDecoration: 'line-through',
  color: '#757475',
}));

interface PriceTextProps {
  bold?: boolean;
  discountColor?: boolean;
  firstRanked?: boolean;
  isText?: boolean;
}

const PriceText = styled(Typography)<PriceTextProps>(
  ({ bold, discountColor, firstRanked, isText }) => ({
    fontWeight: bold ? '600' : '400',
    color:
      discountColor || firstRanked ? '#5D5FEF' : isText ? '#757475' : 'black',
    flexGrow: isText ? 1 : 0,
    textAlign: isText ? 'right' : 'center',
    fontSize: isText ? 12 : 14,
    paddingRight: isText ? 16 : 0,
  }),
);

const BottomContainer = styled('div')(() => ({
  padding: '12px',
  borderTop: '1px solid #eaeaea',
  display: 'flex',
  flexDirection: 'row',
  position: 'relative',
  bottom: 0,
  left: 0,
  right: 0,
  minHeight: '30px',
}));

const BottomTextNoInfo = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  fontSize: 12,
  color: '#979797',
  [theme.breakpoints.down('md')]: {
    fontSize: 10,
  },
}));

const ScrollContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  width: '100%',
  justifyContent: 'center',
}));

interface ScrollButtonProps {
  direction: 'left' | 'right';
}

const ScrollButton = styled(IconButton)<ScrollButtonProps>(({ direction }) => ({
  backgroundColor: '#5d5fef',
  color: '#FFFFFF',
  position: 'absolute',
  zIndex: 2,
  '&:hover': {
    backgroundColor: '#8284ee',
  },
  marginTop: 48,
  right: direction === 'right' ? 100 : 'auto',
  left: direction === 'left' ? 100 : 'auto',
}));

export {
  PACCardContainer,
  TitleCard,
  ContentCard,
  CategoriesCardContainer,
  RowComparator,
  ProviderNameDiv,
  PriceColumn,
  SalePriceText,
  PriceText,
  BottomContainer,
  BottomTextNoInfo,
  TitleComparatorRow,
  RankingComparatorColumn,
  PriceTextContainer,
  ScrollButton,
  ScrollContainer,
};
