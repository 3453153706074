export const COMMENTS = [
  {
    name: 'Paola y Augusto',
    photo: '/assets/images/newLanding/CommentsGoogle/paola.y.augusto.webp',
    comment:
      'Fantástica atención. Muy amables y dispuestos a aclarar todas las dudas. Precios muy convenientes. Les escribí después de haber hecho la compra, para modificar la dirección de envío y el destinatario, y resultó todo perfecto. Gran profesionalismo y calidez en la atención. Llaman por teléfono y envían correo con fotografía del momento de la entrega. Andrea vía Whatsapp, resuelve todo. Felicitaciones.',
  },
  {
    name: 'Andrea Basagoitia',
    photo: '/assets/images/newLanding/CommentsGoogle/andrea.basagoitia.webp',
    comment:
      'Excelente Servicio. Me llegó todo lo que pedí (hasta un medicamento con receta retenida) un día antes de la fecha de despacho original. Me avisaron por WhatsApp y me avisaron al llegar. Lo recomiendo 100%',
  },
  {
    name: 'Ximena Yentzen-Hutchison',
    photo: '/assets/images/newLanding/CommentsGoogle/ximena.yentzen.webp',
    comment:
      'Excelente!! He comprado varias veces ya y, no sólo tienen precios más convenientes, sino que la atención y el seguimiento de mi compra es totalmente personal. Por lo menos así lo siento. 😊👍🏼',
  },
  {
    name: 'María Eugenia Meza',
    photo: '/assets/images/newLanding/CommentsGoogle/maria.eugenia.meza.webp',
    comment:
      'Es una excelente farmacia. Tiene las tres BBB. Encantadora la Andrea que responde el ws. Solucionan todo y llegan más que a tiempo. Muy agradecidas de su atención.',
  },
  {
    name: 'Angela Silva',
    photo: '/assets/images/newLanding/CommentsGoogle/angela.silva.webp',
    comment:
      'Excelente!!! Rapidez, variedad y precios. Y mucha amabilidad, de todos desde venta y despacho',
  },
  {
    name: 'Felipe Merino',
    photo: '/assets/images/newLanding/CommentsGoogle/felipe.merino.webp',
    comment:
      'Siempre tienen lo que necesito a muy buen precio. Comodidad a la hora de pagar, muy bien organizada la página. Fácil de navegar y sobre todo no te vacunan con el precio como lo hacen las farmacias regulares. 100% recomendado.',
  },
  {
    name: 'English Teacher',
    photo: '/assets/images/newLanding/CommentsGoogle/english.teacher.webp',
    comment:
      'Me llegó todo lo que compré el día y hora señaladas sin ningún problema. Muy buena atención. Recomiendo al 100%',
  },
  {
    name: 'Luz Azul',
    photo: '/assets/images/newLanding/CommentsGoogle/luz.azul.webp',
    comment:
      'Compré y me llegó en perfecto estado a mi casa, inclusive un día antes. Muy satisfecha con el proceso de compra y el precio fue muy conveniente. Gracias',
  },
  {
    name: 'Katerine Hrdalo',
    photo: '/assets/images/newLanding/CommentsGoogle/katherine.hrdalo.webp',
    comment:
      'Lo mejor Meki. Lo descubrí y compré mi medicamento mucho más barato que en las demás farmacias!',
  },
  {
    name: 'María Ines Herrera',
    photo: '/assets/images/newLanding/CommentsGoogle/maria.ines.herrera.webp',
    comment:
      'Tienen todo lo que se requiere y a unos precios extraordinariamente bajos… además cuenta con todas las facilidades para comprar por internet y entrega super rápida!!!',
  },
  {
    name: 'Vivian Liddle',
    photo: '/assets/images/newLanding/CommentsGoogle/vivian.liddle.webp',
    comment:
      'Antes gastaba una barbaridad mensual en farmacia. Ahora gasto muchísimo menos! Excelente servicio!',
  },
  {
    name: 'Ana Maria Cavin',
    photo: '/assets/images/newLanding/CommentsGoogle/ana.maria.cavin.webp',
    comment:
      'Muy confiables, llegan siempre a tiempo, cumplen, están en contacto conmigo que es muy bueno.. siempre tengo respuesta',
  },
  {
    name: 'Paula Bouchon',
    photo: '/assets/images/newLanding/CommentsGoogle/paula.bouchon.webp',
    comment:
      'Me encanta Meki 🤩 Tengo todos los remedios en la fecha que los necesito y mucho más barato que en cualquier farmacia y además sin salir de mi casa',
  },
  {
    name: 'Francisca Lyon',
    photo: '/assets/images/newLanding/CommentsGoogle/francisca.lyon.webp',
    comment:
      'Lo que más me gusta es que me recuerden cuándo debo hacer la próxima compra 🗓️, los precios y el profesionalismo',
  },
  {
    name: 'Rafael Valdés',
    photo: '/assets/images/newLanding/CommentsGoogle/rafael.valdes.webp',
    comment:
      'Muchas gracias. Excelente servicio 👌🏻 Cualquier producto que necesite les compraré ',
  },
  {
    name: 'Sylvia Alarcón',
    photo: '/assets/images/newLanding/CommentsGoogle/sylvia.alarcon.webp',
    comment:
      'Lo mejor es que cuentan con medicamentos muy difíciles de encontrar en otras partes 🔍',
  },
];
